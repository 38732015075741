<script setup lang="ts">
  import { ProductPriceTypes } from '@/apps/manage/views/tabs/products';
  import { euro } from '@/services/currency';
  import type { Product } from '@/types/location';
  import { computed } from 'vue';

  const props = defineProps<{
    price_fixed_min: number;
    price_per_person: number;
    number_of_persons: number;
    products: Record<string, Product[]>;
    discount?: number;
  }>();

  const totalPerPerson = computed<number>(() => props.price_per_person * props.number_of_persons);
  const calculateTotalExtraOptions = (products: Record<string, Product[]>, numberOfPersons: number) => {
    return Object.values(products).reduce((total, productList) => {
      return (
        total +
        productList.reduce((sum, product) => {
          return sum + (product.price_type == ProductPriceTypes.PRICE_PER_PERSON ? product.price * numberOfPersons : product.price);
        }, 0)
      );
    }, 0);
  };

  const totalExtraOptions = computed<number>(() => calculateTotalExtraOptions(props.products, props.number_of_persons));
</script>
<template>
  <ul>
    <li>
      <span>Kosten zaalhuur</span>
      <span> {{ euro.format(price_fixed_min) }}</span>
    </li>
    <li>
      <span>{{ euro.format(price_per_person) }} x {{ number_of_persons }} personen</span>
      <span>{{ euro.format(totalPerPerson) }}</span>
    </li>
    <li>
      <span>Extra opties</span>
      <span>{{ euro.format(totalExtraOptions) }}</span>
    </li>
    <li v-if="discount && discount > 0">
      <span>Korting</span>
      <span>- {{ euro.format(discount ?? 0) }}</span>
    </li>
  </ul>
</template>
